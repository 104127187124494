/** @jsx jsx */
import { Container, Flex, jsx, Slider, Box, Text } from 'theme-ui';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Stack, Row, Grid, Col } from '../grid';
import { VisuallyHidden } from '../visually-hidden';

import { Rtl } from '../right-to-left';
import { Checkbox } from '../checkbox';
import { Popover } from '../popover';
import { Dropdown, DropdownItem, DropdownCheckboxItem } from '../dropdown';
import { TextF5 } from '../text-f5';
import { IconLiga, IconFont } from '../../icons';

export const TopToolbar = observer(({ config, active, tester }) => {
  const onDirectionChange = useCallback(
    (e) => {
      config.rtl = e.target.checked;
    },
    [config.rtl]
  );

  const onFontSizeChange = useCallback(
    (e) => {
      config.fontSize = e.target.value;
    },
    [config.fontSize]
  );

  const onFamilyClick = useCallback(
    (family) => {
      const newFont = family.fonts.find((f) => {
        return parseInt(f.weight) >= parseInt(config.font.weight);
      });

      config.family = family;
      config.font = newFont;
    },
    [config.family, config.font]
  );

  const onFeatureChange = useCallback(
    (e) => {
      config.features[e.target.name] = e.target.checked;
    },
    [config]
  );

  return (
    <>
      <Container sx={{}}>
        <Grid
          columns={[9, 9]}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Col span={[8, 3, 3]}>
            <Flex
              sx={{
                alignItems: 'baseline',
                opacity: active ? 1 : 0.65,
              }}
            >
              {}
              {tester.families.length === 1 ? (
                <span sx={{ fontSize: [0, 0, 0, 1], mr: 10 }}>{tester.families[0].name}</span>
              ) : (
                <Box sx={{ mr: [3, 3, 8, 10] }}>
                  <Dropdown align="start" outline={false} trigger={config.family.name}>
                    {tester.families.map((family) => (
                      <DropdownItem
                        value={family}
                        align="start"
                        onSelect={() => onFamilyClick(family)}
                        key={family._id}
                      >
                        <TextF5
                          font={family.fonts.find((f) => parseInt(f.weight) == 700)._id}
                          sx={{ minWidth: '180px' }}
                        >
                          {family.name}
                        </TextF5>
                      </DropdownItem>
                    ))}
                  </Dropdown>
                </Box>
              )}

              <Dropdown align="start" outline={false} trigger={config.font.name}>
                {config.family.fonts.map((style) => (
                  <DropdownItem
                    value={style._id}
                    align="start"
                    onSelect={() => (config.font = style)}
                    key={style._id}
                  >
                    <TextF5 variant="tester.fontStyle" font={style._id} sx={{ minWidth: '140px' }}>
                      {style.name}
                    </TextF5>
                  </DropdownItem>
                ))}
              </Dropdown>
            </Flex>
          </Col>
          <Col span={[0, 5, 5]} sx={{ display: ['none', 'block'] }}>
            <Popover
              disabled={!active}
              align="start"
              outline={false}
              trigger={<Box>Opentype Features</Box>}
            >
              <Box sx={{ p: 4 }}>
                {tester.features.map((feature) => (
                  <div key={feature._key} sx={{ px: [6], py: [4] }}>
                    <Checkbox
                      label={feature.title}
                      name={feature._key}
                      checked={config.features[feature._key] || false}
                      onChange={onFeatureChange}
                      sx={{ fontSize: [1, 1, 2, 2] }}
                      tabIndex={0}
                    />
                  </div>
                ))}
              </Box>
            </Popover>
          </Col>

          <Flex
            sx={{
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              opacity: active ? 1 : 0,
              pt: 6,
              transition: 'opacity 0.4s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
              '> * + *': { ml: 5 },
            }}
          >
            <Box>
              <Popover
                align="center"
                outline={false}
                arrow={false}
                trigger={
                  <Box>
                    <VisuallyHidden>Font Size</VisuallyHidden>
                    <IconFont sx={{ width: '30px' }} />
                  </Box>
                }
              >
                <Box sx={{ width: '40px', height: '290px', padding: 8, overflow: 'hidden' }}>
                  <Slider
                    id={`size-${config._key}`}
                    name={`size-${config._key}`}
                    min="1"
                    max="100"
                    step="1"
                    value={config.fontSize}
                    onChange={onFontSizeChange}
                    disabled={!active}
                    aria-label="Font Size"
                    sx={{
                      width: '250px',
                      margin: 0,
                      transformOrigin: '125px 125px',
                      transform: 'rotate(-90deg)',
                    }}
                  />
                </Box>
              </Popover>
            </Box>
            <div sx={{ display: ['none', 'block'] }}>
              <Rtl id={config._key} name="rtl" checked={config.rtl} onChange={onDirectionChange} />
            </div>
          </Flex>
        </Grid>
      </Container>
    </>
  );
});
