/** @jsx jsx */
import { jsx, Flex, Container, Box } from 'theme-ui';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { useOTF } from '../../woff-provider/use-otf';

import { TopToolbar } from './font-tools-top';
import { useFontLoader } from '../../woff-provider/use-font-loader';

export const TesterRow = observer(({ active, id, config, tester, ...rest }) => {
  const { font, sample, rtl, fontSize, features } = config;
  const ref = useRef();
  const otf = useOTF(font._id);
  useFontLoader(font._id);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    var plaintext = e.clipboardData.getData('text/plain');
    document.execCommand('inserttext', false, plaintext);
  }, []);

  const fontFeatureSettings = Object.keys(features)
    .reduce((res, cur) => {
      if (!features[cur]) return res;

      const keys = tester.features.find((feat) => feat._key === cur).feature.map(({ key }) => key);
      return [...res, ...keys];
    }, [])
    .map((f) => `"${f}" on`)
    .join(',');

  return otf ? (
    <Box
      {...rest}
      ref={ref}
      sx={{
        borderBottom: '1px solid',
        borderColor: 'line',
        position: 'relative',
        '&:focus': {
          outline: 'none',
        },
        '&:focus-visible': {
          outline: '1px solid',
          outlineColor: 'accent',
          outlineOffset: '0',
        },
      }}
      tabIndex={0}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            bg: 'bg.0',
            borderBottom: '1px solid',
            borderColor: 'line',
          }}
        >
          <TopToolbar config={config} tester={tester} active={active} />
        </Flex>
        <Container sx={{ paddingTop: [6, 8], paddingBottom: [10, 11, 12, 12, 13] }}>
          <div
            dir={rtl ? 'rtl' : 'ltr'}
            className={`txt-${otf._id.trim().replace(/-/g, '')}`}
            sx={{
              fontFamily: `"${otf._id}"`,
              outline: 'none',
              opacity: 0,
              lineHeight: 'normal',
              fontVariantLigatures: 'no-common-ligatures',
              fontFeatureSettings: fontFeatureSettings,
              fontSize: [
                `calc(10px + ${fontSize * 0.3}vmin)`,
                `calc(10px + ${fontSize * 0.25}vmax)`,
              ],
              direction: rtl ? 'rtl' : 'ltr',
            }}
            lang={sample ? sample.script.code : 'en'}
            contentEditable={true}
            suppressContentEditableWarning={true}
            spellCheck={false}
            onPaste={handlePaste}
            onFocus={() => (tester.rowActive = id)}
          >
            {sample.sample}
          </div>
        </Container>
      </Box>
    </Box>
  ) : null;
});

export default TesterRow;
