import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import { useFontLoader } from '../woff-provider/use-font-loader';

import { TypefaceBanner } from '../components/typeface-banner';

import { TypefaceTester } from '../components/typeface-tester';
import { TypefaceHero } from '../components/typeface-hero';

import { TypefaceFeatures } from '../components/typeface-features';
import { TypefaceInfo } from '../components/typeface-info';
import { TypefaceShop } from '../components/typeface-shop';
import { PromoBanner } from '../components/promo-banner';

import { Row } from '../components/grid';
import { Text, Container } from 'theme-ui';
export const query = graphql`
  query TesterTemplateQuery($id: String!) {
    typeface: sanityTypeface(_id: { eq: $id }) {
      ...SanityTypefaceFragment
    }
  }
`;

const PageTypefaceTemplate = (props) => {
  const { data, errors } = props;
  const typeface = data && data.typeface;

  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {errors && <GraphQLErrorList errors={errors} />}
      {typeface && <SEO {...typeface.seo} />}
      {typeface.promoSlotTop && <PromoBanner {...typeface.promoSlotTop} />}
      <TypefaceBanner
        title={typeface.title}
        banner={typeface.banner}
        specimen={typeface.info.specimen}
      />

      {typeface.hero && typeface.hero.length > 0 && <TypefaceHero {...typeface.hero[0]} />}
      {typeface.tester && typeface.tester.rows ? (
        <TypefaceTester tester={typeface.tester} families={typeface.families} />
      ) : null}
      {typeface.features && typeface.features.rows.length > 0 && (
        <TypefaceFeatures data={typeface.features} families={typeface.families} />
      )}
      <TypefaceInfo data={typeface.info} />
      <TypefaceShop typeface={typeface.slug.current} data={typeface.products} />
    </>
  );
};

export default PageTypefaceTemplate;
