/** @jsx jsx */
import { jsx, Container, Flex, Slider, Box, Text } from 'theme-ui';
import React, { useMemo } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import useMedia from 'use-media';

import { Stack, Row } from '../grid';
import { TesterRow } from './tester-row';

const baseSizes = {
  xs: 7,
  sm: 18,
  md: 26,
  lg: 32,
  xl: 48,
  xxl: 68,
  xxxl: 88,
};

export const TypefaceTester = observer(({ tester, families }) => {
  const store = useLocalStore(() => {
    const rows = tester.rows.reduce((res, entry) => {
      const { _key, font, fontSize, sample } = entry;

      const selectedFamily =
        families.find((fam) => {
          const includesSelectedFont = fam.fonts.findIndex((f) => f._id === font._id) > -1;
          return includesSelectedFont;
        }) || families[0];

      const rowFont =
        selectedFamily.fonts.find((f) => f._id === font._id) || selectedFamily.fonts[0];

      const testerRowConfig = {
        _key,
        family: selectedFamily,
        font: rowFont,
        rtl: sample.rtl,
        fontSize: baseSizes[fontSize],
        sample,
        features: {},
      };
      res.push(testerRowConfig);
      return res;
    }, []);

    return {
      rows: rows,
      tester: {
        rowActive: null,
        features: tester.features,
        families: families,
        open: null,
      },
    };
  });

  return (
    <>
      <Stack gap={null}>
        {store.rows.map((entry, i) => (
          <TesterRow
            active={true}
            id={entry._key}
            key={entry._key}
            config={entry}
            tester={store.tester}
          />
        ))}
      </Stack>
    </>
  );
});

export default TypefaceTester;
