/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import * as RadixPopover from '@radix-ui/react-popover';

import { Chevron } from '../../icons';

export const Popover = ({ trigger, align = 'end', outline = true, arrow = true, children }) => {
  const onOpenChange = (open) => {
    if (!open) {
      document.body.style.pointerEvents = null;
    }
  };

  return (
    <RadixPopover.Root onOpenChange={onOpenChange}>
      <RadixPopover.Trigger
        sx={{
          fontSize: [0, 0, 0, 1],
          position: 'relative',
          py: outline ? 6 : 0,
          pl: outline ? 5 : 0,
          pr: arrow ? 10 : 0,
          lineHeight: '1.5',
          textAlign: align === 'end' ? 'right' : 'left',
          color: 'text',
          border: outline ? '1px solid' : 0,
          borderColor: 'line',
          whiteSpace: 'nowrap',
          '&:focus': {
            outline: '1px solid accent',
            outlineOffset: '2px',
          },
          '&:focus-visible': {
            color: 'text',
          },
        }}
      >
        {trigger}
        {arrow && (
          <div
            sx={{
              position: 'absolute',
              right: [6],
              top: '50%',
              transform: 'translateY(-25%)',
              width: [6],
              '& > svg': {
                width: '100%',
              },
            }}
          >
            <Chevron />
          </div>
        )}
      </RadixPopover.Trigger>
      <RadixPopover.Content
        sideOffset={-1}
        align={align}
        style={{
          zIndex: 999,
        }}
        sx={{
          maxHeight: '300px',
          overflow: 'scroll',
          width: '100%',
          backgroundColor: 'bg.0',
          mt: 7,
          py: 0,
          position: 'relative',
          border: '1px solid',
          borderColor: 'line',
          '&:focus-within': {
            outline: '1px solid accent',
            outlineOffset: '4px',
          },
        }}
      >
        {children}
      </RadixPopover.Content>
    </RadixPopover.Root>
  );
};
