/** @jsx jsx */
import React, { useMemo, useCallback } from 'react';
import { Container, Text, Flex, Box, jsx, Slider } from 'theme-ui';
import { TextF5 } from '../text-f5';
import { Anchor } from '../link';
import { Grid, Row, Col, Stack } from '../grid';
import { useFontLoader } from '../../woff-provider/use-font-loader';
import { useOTF } from '../../woff-provider/use-otf';
import { observer, useLocalStore } from 'mobx-react';
import { Checkbox } from '../checkbox';

import { HeroTools } from './hero-tools';

const baseSizes = {
  xs: 10,
  sm: 18,
  md: 26,
  lg: 32,
  xl: 56,
  xxl: 72,
  xxxl: 100,
};

export const TypefaceHero = observer(({ paddingTop, fontSize, font, sample }) => {
  useFontLoader(font._id);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    var plaintext = e.clipboardData.getData('text/plain');
    document.execCommand('inserttext', false, plaintext);
  }, []);

  const axes = font.axes.reduce((res, axis) => {
    res[axis.tag] = axis.default;
    return res;
  }, {});

  const store = useLocalStore(() => {
    return {
      font: font,
      rtl: sample.rtl,
      fontSize: baseSizes[fontSize],
      axes: axes,
      sample,
    };
  });

  const fontVariationSettings = font.axes
    .reduce((res, axis) => {
      if (axis.tag === 'opsz') {
        res.push(`"${axis.tag}" ${parseFloat(store.axes[axis.tag]) + 0.1}`);
      } else {
        res.push(`"${axis.tag}" ${store.axes[axis.tag]}`);
      }
      return res;
    }, [])
    .join(', ');

  return (
    <div sx={{ borderBottom: '1px solid', borderColor: 'line' }}>
      <Container
        sx={{
          paddingTop: 0,
          paddingBottom: [6],
          borderBottom: '1px solid',
          borderColor: 'line',
          bg: 'bg.0',
          borderBottom: '1px solid',
          borderColor: 'line',
          zIndex: 1,
        }}
      >
        <HeroTools store={store} />
      </Container>
      <Container>
        <div
          dir={store.rtl ? 'rtl' : 'ltr'}
          className={`txt-${font._id.trim().replace(/-/g, '')}`}
          sx={{
            textAlign: 'center',
            fontFamily: `"${font._id}"`,
            outline: 'none',
            opacity: 0,
            fontVariantLigatures: 'no-common-ligatures',
            fontVariationSettings: fontVariationSettings,
            pt: paddingTop ? [12, 16] : [8],
            pb: [10],
            fontSize: [
              `calc(10px + ${store.fontSize * 0.2}vmin)`,
              `calc(10px + ${store.fontSize * 0.2}vmin)`,
              `calc(10px + ${store.fontSize * 0.2}vmax)`,
              `calc(10px + ${store.fontSize * 0.2}vmax)`,
              `calc(10px + ${store.fontSize * 0.2}vmax)`,
            ],
            lineHeight: 1.1,
            direction: store.rtl ? 'rtl' : 'ltr',
          }}
          lang={store.sample ? store.sample.script.code : 'en'}
          contentEditable={true}
          suppressContentEditableWarning={true}
          spellCheck={false}
          onPaste={handlePaste}
        >
          {store.sample.sample}
        </div>
      </Container>
    </div>
  );
});
