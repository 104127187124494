/** @jsx jsx */
import { jsx, Text } from 'theme-ui';

import { observer } from 'mobx-react';

export const Checkbox = observer(({ name, label, checked, onChange }) => {
  return (
    <div
      sx={{
        pr: [9, 9],
        flex: 0,
        flexDirection: 'row',
        position: 'relative',
        '&:focus-within': {
          color: 'accent',
        },
      }}
    >
      <div
        sx={{
          position: 'absolute',
          display: 'block',
          width: [6, 7],
          height: [6, 7],
          flex: 0,
          right: '0px',
          top: '50%',
          transform: 'translateY(-50%)',
          marginTop: '2px',
          // border: '1px solid',
          // borderColor: 'currentColor',
          padding: '3px',
        }}
      >
        {checked && (
          <svg
            width="15"
            height="11"
            viewBox="0 0 15 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 4.89953L5.27858 9L13 1" stroke="currentColor" strokeWidth="2" />
          </svg>
        )}

        {/* <div
          sx={{
            backgroundColor: checked ? 'currentColor' : 'transparent',
            width: '100%',
            height: '100%',
            borderColor: 'text',
          }}
        ></div> */}
      </div>
      <Text as="label" htmlFor={name} sx={{ fontSize: '16px', ml: 2, color: 'text' }}>
        {label}
      </Text>
      <input
        name={name}
        onChange={onChange}
        type="checkbox"
        checked={checked}
        sx={{
          position: 'absolute',
          top: '0',
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0,
        }}
      />
    </div>
  );
});
export default Checkbox;
