/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react';

const Path = (props) => (
  <g transform="translate(0,1)">
    <motion.path fill="transparent" strokeWidth="3" stroke="currentColor" {...props} />
  </g>
);

export const Rtl = observer(({ id, name, checked, onChange, ...rest }) => {
  return (
    <motion.div
      animate={checked ? 'rtl' : 'ltr'}
      initial="ltr"
      sx={{
        position: 'relative',
        flex: 0,
        ':hover': {
          color: 'accent',
        },
        ':focus-within': {
          outline: '1px solid',
          outlineColor: 'accent',
          outlineOffset: '0px',
          '&:hover': {
            outline: 'none',
          },
        },
      }}
      {...rest}
    >
      <svg
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={{ width: '30px', height: '30px', display: 'block' }}
      >
        <Path
          strokeWidth="2.4"
          stroke="currentColor"
          variants={{
            rtl: { d: 'M 14 12 L 30 12' },
            ltr: { d: 'M 8 12 L 24 12' },
          }}
        />

        <Path
          strokeWidth="2.4"
          stroke="currentColor"
          variants={{
            rtl: { d: 'M 10 20 L 30 20' },
            ltr: { d: 'M 8 20 L 28 20' },
          }}
        />

        <Path
          strokeWidth="2.4"
          stroke="currentColor"
          variants={{
            rtl: { d: 'M 18 28 L 30 28' },
            ltr: { d: 'M 8 28 L 20 28' },
          }}
        />
      </svg>
      <label
        htmlFor={`rtl-${id}`}
        css={`
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border-width: 0;
        `}
      >
        Right to Left
      </label>
      <input
        id={`rtl-${id}`}
        name="rtl"
        onChange={onChange}
        type="checkbox"
        checked={checked}
        sx={{
          position: 'absolute',
          top: '0',
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0,
          margin: 0,
        }}
      />
    </motion.div>
  );
});
export default Rtl;
