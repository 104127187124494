/** @jsx jsx */
import React, { useMemo } from 'react';
import { Container, Text, Flex, Box, jsx } from 'theme-ui';
import { TextF5 } from '../text-f5';
import { Anchor } from '../link';
import { Grid, Row, Col, Stack } from '../grid';

export const TypefaceBanner = ({ title, banner, specimen }) => {
  return (
    <header>
      <Container>
        <Row>
          <Stack variant="0">
            <Stack gap={[8]}>
              <Text as="h1" variant="section_header" sx={{ opacity: 0.5 }}>
                {title}
              </Text>
              <TextF5 as="p" className="type" variant="f5_typeface_tagline" font={banner.font._id}>
                {banner.text}
              </TextF5>
            </Stack>
            <Flex sx={{ mt: 6 }}>
              <Anchor to="#shop" variant="buttons.primary">
                {banner.buyCta}
              </Anchor>
              {specimen ? (
                <Anchor
                  href={specimen.asset.url}
                  variant="buttons.ghost"
                  external
                  sx={{ ml: [5, 10] }}
                >
                  PDF Specimen
                </Anchor>
              ) : null}
            </Flex>
          </Stack>
        </Row>
      </Container>
      <Container
        sx={{
          borderTop: 'line',
          borderBottom: 'line',
          borderColor: 'line',
          backgroundColor: ['bg.1'],

          pt: [8, 7, 9],
          pb: [9, 8, 10],
        }}
      >
        <Grid columns={[1, banner.info.length]} gap={[7, 0]}>
          {banner.info.map((info) => (
            <Col key={info._key} as="p">
              <Text
                as="span"
                variant="copy"
                sx={{ display: 'block', color: ['txt.1'], opacity: 0.65 }}
              >
                {info.title}
              </Text>
              <Text
                as="span"
                variant="copy"
                sx={{ display: 'block', fontWeight: 'bold', color: ['txt.1'] }}
              >
                {info.info}
              </Text>
            </Col>
          ))}
        </Grid>
      </Container>
    </header>
  );
};
