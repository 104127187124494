/** @jsx jsx */
import { Container, Flex, jsx, Slider, Box, Text } from 'theme-ui';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { VisuallyHidden } from '../visually-hidden';
import { Rtl } from '../right-to-left';
import { Checkbox } from '../checkbox';
import { Dropdown } from '../dropdown';
import { Popover } from '../popover';

import { Stack } from '../grid';
import { IconLiga, IconFont } from '../../icons';

export const HeroTools = observer(({ store }) => {
  const onDirectionChange = useCallback(
    (e) => {
      store.rtl = e.target.checked;
    },
    [store]
  );

  const onFontSizeChange = useCallback(
    (e) => {
      store.fontSize = e.target.value;
    },
    [store]
  );

  return (
    <>
      <Flex
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            pt: 6,
          }}
        >
          <Popover align="start" outline={false} trigger={store.font.name}>
            <Box sx={{ px: 6, pt: 5, pb: 8 }}>
              <Stack gap={9}>
                <Stack gap={6}>
                  <Text
                    as="label"
                    sx={{
                      fontSize: [0, 1],
                      color: 'txt.1',
                      display: 'block',
                      flex: '0 0 auto',
                    }}
                  >
                    Font Size
                  </Text>
                  <Slider
                    min="1"
                    max="100"
                    step="1"
                    value={store.fontSize}
                    onChange={onFontSizeChange}
                    sx={{ width: '200px' }}
                  />
                </Stack>
                {store.font.axes.map((axis) => (
                  <Stack gap={6} key={axis.tag}>
                    <Text
                      as="label"
                      sx={{
                        fontSize: [0, 1],
                        color: 'txt.1',
                        display: 'block',
                        flex: '0 0 auto',
                      }}
                    >
                      {axis.name}: {store.axes[axis.tag]}
                    </Text>
                    <Slider
                      min={axis.min}
                      max={axis.max}
                      value={store.axes[axis.tag]}
                      onChange={(e) => (store.axes[axis.tag] = e.target.value)}
                      step={axis.tag === 'ital' ? 0.01 : 1}
                      sx={{ width: '200px' }}
                    />
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Popover>
        </Flex>
      </Flex>
    </>
  );
});
